/* You can add global styles to this file, and also import other style files */

body {
  background-color: #1d1d1d;
  color: #ccc;
}

/* some styles for the webgl fluid simulation / canvas */
webgl-fluid-simulation {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  z-index: -1;
}

webgl-fluid-simulation canvas {
  width: 100%;
  height: 100%;
  /* the canvas position cannot be absolute, otherwise the js resize will bug out */
  position: fixed;
}

h1,
section {
  margin-left: 20px;
}

pre {
  margin: 10px;
  padding: 20px;
  border: 1px solid #ccc;
  display: inline-block;
  max-width: 80vw;
  white-space: pre-wrap;
}

main {
  max-width: 777px;
  margin: auto;
}
